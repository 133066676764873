import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Image from './Image';
import useCustomers from '../hooks/useCustomers';

function Customer({ customer }) {
  return (
    <div className="box customer-grid-cell" title={customer.title}>
      <Image
        imageInfo={{
          alt: `Logo da empresa ${customer.title}`,
          image: customer.logo,
        }}
      />
    </div>
  );
}

function Customers() {
  const customers = useCustomers();

  return (
    <div className="customers-grid">
      {customers.map(({ node: { id, frontmatter: customer } }) =>
        customer.link ? (
          <OutboundLink
            key={id}
            href={customer.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Customer customer={customer} />
          </OutboundLink>
        ) : (
          <Customer customer={customer} key={id} />
        )
      )}
    </div>
  );
}

export default Customers;
