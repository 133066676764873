import React from 'react';
import ServicesGrid from './ServicesGrid';

function Services() {
  return (
    <section className="section" id="servicos">
      <div className="container content">
        <h2 className="title has-text-white">Serviços</h2>
        <ServicesGrid />
      </div>
    </section>
  );
}

export default Services;
