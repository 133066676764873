import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Contact from '../components/Contact';
import Customers from '../components/Customers';
import Layout from '../components/Layout';
import Services from '../components/Services';
import SEO from '../components/SEO';
import useSiteMetadata from '../hooks/useSiteMetadata';

export function IndexPageTemplate({
  banner,
  bannerText,
  mainpitch,
  notice,
  visible,
}) {
  const { headline } = useSiteMetadata();

  return (
    <>
      <SEO titleTemplate={`%s | ${headline}`} />
      <section className="section section-light section-banner">
        <GatsbyImage
          alt=""
          image={banner.childImageSharp.gatsbyImageData}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <h1>{bannerText}</h1>
        <OutboundLink href="#contato">Contate-nos</OutboundLink>
      </section>
      {visible && (
        <section className="section section-light section-notice">
          <div className="container content">
            <p className="box">{notice}</p>
          </div>
        </section>
      )}
      {mainpitch && mainpitch.description && (
        <section className="section section-light">
          <div className="container content">
            {mainpitch.title && <h2 className="title">{mainpitch.title}</h2>}
            <p>{mainpitch.description}</p>
          </div>
        </section>
      )}
      <Services />
      <section className="section section-light" id="clientes">
        <div className="container content">
          <h2 className="title">Clientes</h2>
          <Customers />
        </div>
      </section>
      <Contact />
    </>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mainpitch: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        notice
        visible
        banner {
          id
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
        bannerText
        mainpitch {
          title
          description
        }
      }
    }
  }
`;
