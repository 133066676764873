import { graphql, useStaticQuery } from 'gatsby';

function useCustomers() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query CustomersGridQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "customer" }
              visible: { eq: true }
            }
          }
          sort: { fields: [frontmatter___title] }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                link
                logo {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      height: 100
                      quality: 100
                      layout: FIXED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges;
}

export default useCustomers;
