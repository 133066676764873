import React from 'react';
import useContactInfo from '../hooks/useContactInfo';
import useSiteMetadata from '../hooks/useSiteMetadata';

function Map() {
  const { address } = useContactInfo();
  const { defaultTitle } = useSiteMetadata();

  return (
    <div className="box box-map">
      <iframe
        width="100%"
        height="100%"
        id="gmap_canvas"
        src={[
          'https://maps.google.com/maps?q=',
          encodeURIComponent(address),
          '&t=&z=13&ie=UTF8&iwloc=&output=embed',
        ].join()}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        title={`Localização da ${defaultTitle}`}
      />
    </div>
  );
}

export default Map;
