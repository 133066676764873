import React from 'react';
import useServices from '../hooks/useServices';
import Image from './Image';

function ServicesGrid() {
  const services = useServices();

  return (
    <div className="services-grid">
      {services.map(({ node: { id, fields, frontmatter: service } }) => (
        <div className="box service-grid-cell" key={id}>
          <div className="service-grid-tag">
            <Image
              imageInfo={{
                alt: `Símbolo do serviço ${service.title}`,
                image: service.featuredimage,
              }}
            />
          </div>
          {service.title}
          </div>
      ))}
    </div>
  );
}

export default ServicesGrid;
