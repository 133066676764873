import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import useContactInfo from '../hooks/useContactInfo';
import Map from './Map';

function onlyNumbers(value) {
  return value.replace(/[^\d]/g, '');
}

function Contact() {
  const {
    address,
    businessHours,
    comercialEmail,
    personalEmail,
    phones = [],
    whatsapp,
    whatsappMsg,
  } = useContactInfo();

  return (
    <section className="section" id="contato">
      <div className="container content">
        <h2 className="title has-text-white">Contato</h2>
        <Map />
        <div className="contact-grid has-text-white">
          <div className="contact-grid-cell" style={{ gridArea: 'address' }}>
            <p>
              <strong>Endereço:</strong>
            </p>
            <OutboundLink
              href={[
                'https://www.google.com/maps/search/?api=1&query=',
                address.replace(/\s+/g, '+'),
              ].join('')}
              rel="noopener noreferrer"
              target="_blank"
            >
              <p>{address}</p>
            </OutboundLink>
          </div>
          <div className="contact-grid-cell" style={{ gridArea: 'phones' }}>
            <p>
              <strong>Telefones:</strong>
            </p>
            <ul>
              {phones.map(p => (
                <li key={p}>{p}</li>
              ))}
            </ul>
          </div>
          <div
            className="contact-grid-cell"
            style={{ gridArea: 'comercialEmail' }}
          >
            <p>
              <strong>E-mail comercial:</strong>
            </p>
            <OutboundLink href={`mailto:${comercialEmail}`}>
              <p>{comercialEmail}</p>
            </OutboundLink>
          </div>
          <div
            className="contact-grid-cell"
            style={{ gridArea: 'businessHours' }}
          >
            <p>
              <strong>Horário de funcionamento:</strong>
            </p>
            <p>{businessHours}</p>
          </div>
          <div className="contact-grid-cell" style={{ gridArea: 'whatsapp' }}>
            <p>
              <strong>WhatsApp:</strong>
            </p>
            <OutboundLink
              href={[
                'https://api.whatsapp.com/send?phone=55',
                onlyNumbers(whatsapp),
                `&text=${encodeURIComponent(whatsappMsg)}!`,
              ].join('')}
              rel="noopener noreferrer"
              target="_blank"
            >
              <p>{whatsapp}</p>
            </OutboundLink>
          </div>
          <div
            className="contact-grid-cell"
            style={{ gridArea: 'personalEmail' }}
          >
            <p>
              <strong>E-mail pessoal:</strong>
            </p>
            <OutboundLink href={`mailto:${personalEmail}`}>
              <p>{personalEmail}</p>
            </OutboundLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
