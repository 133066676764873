import { graphql, useStaticQuery } from 'gatsby';

function useServices() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ServicesGridQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "service-page" } } }
          sort: { fields: [frontmatter___title] }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      height: 50
                      layout: FIXED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges;
}

export default useServices;
